export const callGetApi = async (url) => {
  let res;
  let header = {
    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
  };
  if (process.env.ENVIRONMENT === 'localhost') {
    header = {
      'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Access-Control-Allow-Origin': 'http://localhost:8000',
    };
  }
  const response = await fetch(url, {
    method: 'get',
    headers: header,
  });
  res = response;
  return res;
  //   if (response.status === 200) {
  // let list = await response.json();
  // list.map((rec) => {
  //   setNews((news) => [
  //     ...news,
  //     {
  //       date: rec.date,
  //       message: rec.message,
  //     },
  //   ]);
  // });
  //   } else {
  //     response.json().then((data) => {});
  //   }
};

export default callGetApi;
