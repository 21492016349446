import React, { useState, useEffect } from 'react';
import { Box, ResponsiveContext, Accordion, AccordionPanel, Text } from 'grommet';
import { callGetApi } from '../../components/internal/callApi';
const functionURL = process.env.FUNCTION_URI
  ? process.env.FUNCTION_URI + `/api/ReadFile?type=qa`
  : `/api/ReadFile?type=qa`;

const Qa = (props) => {
  const [qa, setQa] = useState([]);
  useEffect(() => {
    async function fetchData() {
      setQa((state) => state.filter((item) => item.category === ''));

      const response = await callGetApi(functionURL + '&id=' + props.id);
      if (response.status === 200) {
        let list = await response.json();
        let tmpList = [];
        list.map((tmp, index) => {
          let row = -1;
          if (tmpList.length > 0) {
            row = tmpList.findIndex((x) => x.category === tmp.category);
          }
          if (row === -1) {
            tmpList.push({
              category: tmp.category,
              values: [],
            });
            row = tmpList.length - 1;
          }
          tmpList[row].values.push({
            question: tmp.question,
            answer: tmp.answer,
          });
          return '';
        });
        setQa(tmpList);
      } else {
        response.json().then((data) => {});
      }
    }
    fetchData();
  }, []);

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
        } else {
        }
        return (
          <>
            <Box pad="medium" style={{ marginBottom: '2.5rem', lineHeight: '1.8' }}>
              <Box justify="center" align="center" style={{ marginBottom: '1.5rem' }}>
                <h1 style={{ fontSize: '1.5rem', marginBottom: '0rem' }}>
                  {'よくあるご質問 （'}
                  {props.title}
                  {'）'}
                </h1>
                {props.linkList ? (
                  <Box pad="medium" style={{ marginLeft: 'auto' }}>
                    <span>{'<外部サイト>'}</span>
                    {props.linkList?.map((item, index) => (
                      <a href={item.link}>{item.label}</a>
                    ))}
                  </Box>
                ) : (
                  ''
                )}
              </Box>
              <Box
                style={{
                  marginBottom: '1.5rem',
                }}
              >
                <Box pad="medium">
                  {qa?.map((category, index) => {
                    return (
                      <Box key={'category_' + index} style={{ marginBottom: '2.5rem' }}>
                        <h2 style={{ fontSize: '1rem' }}>{category.category}</h2>
                        <Accordion multiple={true}>
                          {category.values.map((rec, qaIndex) => {
                            const list = rec.answer?.split('\r\n');
                            return (
                              <Box style={{ marginBottom: '1.5rem' }}>
                                <AccordionPanel
                                  className="accordionPanel"
                                  label={rec.question}
                                  key={'panel_' + qaIndex}
                                  background="light-1"
                                  border="light"
                                >
                                  <Box pad="medium" border="light">
                                    {list.map((x, textIndex) => {
                                      return <Text key={'text_' + textIndex}>{x}</Text>;
                                    })}
                                  </Box>
                                </AccordionPanel>
                              </Box>
                            );
                          })}
                        </Accordion>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default Qa;
