import * as React from 'react';
import Layout from '../../components/layout/layout';
import Seo from '../../components/seo';
import Qa from '../../components/qa/qa';

const QAForklorePage = () => (
  <Layout>
    <Seo title="よくあるお問い合わせ　FORKLORE全般" />
    <Qa id="forklore" title="FORKLORE全般"></Qa>
  </Layout>
);

export default QAForklorePage;
